import React from 'react';

/**
 * Renders information about the user obtained from Microsoft Graph
 */
export const ProfileData = (props) => {
    return (
        <div id='profile-div' style={{ textAlign: 'center' }}>
            <p><strong>Last Name: </strong> {props.graphData.surname}</p>
            <p><strong>First Name: </strong> {props.graphData.givenName}</p>
            <p><strong>Email: </strong> {props.graphData.mail}</p>
        </div>
    );
};
