export const msalConfig = {
  auth: {
    clientId: '1687d845-4f44-4ec4-be90-b244f8c830ab',
    authority: 'https://login.microsoftonline.com/organizations', // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: 'https://sia-react-aadpoc.chongtingfong.com',
    // redirectUri: 'http://localhost:3000',
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to 'true' if you are having issues on IE11 or Edge
  }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ['User.Read']
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me'
};

export const tenantsAllowed = [
  { tenantName: 'chongtingfong', tenantId: 'db1f8e73-d9e7-4679-9bb3-f58a1a766c5c' },
  { tenantName: 'Singapore Airlines Limited', tenantId: 'd56c1b0d-cb31-4a85-93e0-11f8d2a215e0' }
];
