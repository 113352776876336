import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import { useIsAuthenticated } from '@azure/msal-react';
import { SignInButton } from './SignInButton';
import { SignOutButton } from './SignOutButton';

/**
 * Renders the navbar component with a sign-in button if a user is not authenticated
 */
export const PageLayout = (props) => {
  const isAuthenticated = useIsAuthenticated();
  return (
    <>
      <Navbar bg='primary' variant='dark' style={{ padding: '12px 24px 12px 24px' }}>
        <a className='navbar-brand' href='/'>Multi-Tenant Azure AD Auth</a>
        {isAuthenticated ? <SignOutButton /> : <SignInButton />}
      </Navbar>
      <div style={{ margin: '24px', textAlign: 'center' }}>
        {props.children}
      </div>
    </>
  );
};
