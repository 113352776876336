import React, { useState } from 'react';
import { PageLayout } from './components/PageLayout';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { loginRequest, tenantsAllowed } from './authConfig';
import Button from 'react-bootstrap/Button';

import { ProfileData } from './components/ProfileData';
import { callMsGraph } from './graph';

function App() {
  return (
    <PageLayout>
      <AuthenticatedTemplate>
        <ProfileContent />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <p>You are not signed in! Please sign in.</p>
      </UnauthenticatedTemplate>
    </PageLayout>
  );
}

function ProfileContent() {
  const { instance, accounts } = useMsal();
  const [graphData, setGraphData] = useState(null);

  const name = accounts[0] && accounts[0].name;
  const tenantId = accounts[0] && accounts[0].tenantId;
  const isTenantAllowed = tenantsAllowed.filter(t => t.tenantId === tenantId).length > 0;
  const tenantName = isTenantAllowed && tenantsAllowed.filter(t => t.tenantId === tenantId)[0].tenantName;

  function RequestProfileData() {
    const request = {
      ...loginRequest,
      account: accounts[0]
    };

    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance.acquireTokenSilent(request).then((response) => {
      callMsGraph(response.accessToken).then(response => setGraphData(response));
    }).catch((e) => {
      instance.acquireTokenPopup(request).then((response) => {
        callMsGraph(response.accessToken).then(response => setGraphData(response));
      });
    });
  }

  return (
    <>
      <h5 className='card-title' style={{ margin: '12px 24px 12px 24px' }}>{tenantName}</h5>
      <h5 className='card-title' style={{ margin: '12px 24px 12px 24px' }}>Welcome {name}</h5>
      {isTenantAllowed && <>{graphData ? <ProfileData graphData={graphData} /> : <Button variant='secondary' onClick={RequestProfileData}>Request Profile Information</Button>}</>}
      {!isTenantAllowed &&
        <>
          <div id='profile-div' style={{ textAlign: 'center' }}>
            <p>You are not allowed to access this system.</p>
          </div>
        </>
      }
    </>
  );
};

export default App;